import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRule, StructuredText } from "react-datocms"
import { isParagraph } from "datocms-structured-text-utils"
import Moment from "react-moment"

import { Container } from "../UI"
import SectionTitle from "../SectionTitle"
import { useTosPageData } from "../../system/DatoCms/TosPageData"
// import JoinWaitlist from "../JoinWaitlist"

const TosPage = () => {
  const { title, body, meta } = useTosPageData()
  return (
    <section className="pt-[100px] font-spartan">
      <Container>
        <SectionTitle>{title}</SectionTitle>
        <div className="mx-auto mb-[100px] max-w-[1000px]">
          <div className="mb-[10px] md:text-right">
            <span className="font-spartan text-sm text-gray-2">
              Last updated:{" "}
              <Moment format="DD MMMM YYYY">{meta.updatedAt}</Moment>
            </span>
          </div>
          <div className="accordian-structured-text list-style-custom">
            <StructuredText
              data={body}
              renderBlock={({ record }) => {
                switch (record.__typename) {
                  case "DatoCmsImage":
                    return (
                      <GatsbyImage
                        image={record.image.gatsbyImageData}
                        alt={record.image.alt}
                      />
                    )
                  default:
                    return null
                }
              }}
              customRules={[
                renderRule(isParagraph, ({ children, key }) => {
                  if (children[0].length === 0) return <br />
                  return (
                    <p key={key} className="my-4">
                      {children}
                    </p>
                  )
                }),
              ]}
            />
          </div>
        </div>
      </Container>
      {/* <JoinWaitlist /> */}
    </section>
  )
}

export default TosPage
